/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-12-15",
    versionChannel: "nightly",
    buildDate: "2023-12-15T00:15:10.699Z",
    commitHash: "c43f76eb2b72b817527dfb4be80ed4fe9848ae0f",
};
